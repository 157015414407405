
import Vue from "vue";
import {Component} from "vue-property-decorator";
import QrcodeVue from "qrcode.vue";

@Component({
  components: {
    QrcodeVue
  }
})
export default class AppDownloadView extends Vue {

  /**
   * returns the google play store link
   * @private
   */
  private get googlePlayQrCode(): string {
    return process.env.VUE_APP_GOOGLE_PLAY_STORE!;
  }

  /**
   * returns the apple app store link
   * @private
   */
  private get appleQrCode(): string {
    return process.env.VUE_APP_APPLE_APP_STORE!;
  }
}
